// ----------------------------------------------------------------------

export const PATH_PAGE = {
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  privacy: '/privacy',
  TOS: '/terms-of-service',
  cookies: '/cookies',
  userGuidelines: '/user-guidelines',
  page404: '/404',
  page500: '/500'
};

// export const PATH_DASHBOARD = 'https://app.repofilesync.com';
// export const SIGNIN_PATH_DASHBOARD = 'https://app.repofilesync.com/auth/login';
// export const REGISTER_PATH_DASHBOARD = 'https://app.repofilesync.com/auth/register';

export const PATH_DASHBOARD = '/';
export const SIGNIN_PATH_DASHBOARD = '/signup';
export const REGISTER_PATH_DASHBOARD = '/signup';
export const PATH_WAITLIST = '/waitlist';
