// material
import { Box, BoxProps } from '@mui/material';

interface LogoProps extends BoxProps {
  fullLogo?: boolean;
}

// ----------------------------------------------------------------------

export default function Logo({ sx, fullLogo }: LogoProps) {
  if (fullLogo) {
    return (
      <Box
        component="img"
        src={`/static/brand/logo_full.svg`}
        sx={{ width: 248, height: 62, ...sx }}
      />
    );
  }
  return (
    <Box
      component="img"
      src={`/static/brand/logo_single.svg`}
      sx={{ width: 62, height: 62, ...sx }}
    />
  );
}
