// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography, CardContent } from '@mui/material';
// routes
// layouts
// components
import Page from 'components/Page';

import RegisterForm from './RegisterForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundPositionX: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${'/static/login-register-background.jpg'})`,
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register | Repo File Sync">
      <Container>
        <ContentStyle>
          <Card>
            <CardContent>
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4">Create your account</Typography>
                </Box>
              </Box>

              {/* Disable OAuth socials for now */}
              {/* <AuthFirebaseSocials /> */}

              <RegisterForm />
            </CardContent>
          </Card>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
