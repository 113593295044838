import ReactMarkdown from 'markdown-to-jsx';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ReactNode } from 'react';

const MarkdownTable = (props: { children: ReactNode }) => {
  return (
    <TableContainer component={Paper}>
      <Table>{props.children}</Table>
    </TableContainer>
  );
};

const MarkdownTableCell = (props: { children: ReactNode }) => (
  <TableCell>
    <Typography>{props.children}</Typography>
  </TableCell>
);

const MarkdownTableRow = (props: { children: ReactNode }) => <TableRow>{props.children}</TableRow>;

const MarkdownTableBody = (props: { children: ReactNode }) => (
  <TableBody>{props.children}</TableBody>
);
const MarkdownTableHead = (props: { children: ReactNode }) => (
  <TableHead {...props}>{props.children}</TableHead>
);

const options = {
  overrides: {
    h1: {
      component: (props: any) => <Typography gutterBottom variant="h4" {...props} />
    },
    h2: {
      component: (props: any) => <Typography gutterBottom variant="h6" {...props} />
    },
    h3: {
      component: (props: any) => <Typography gutterBottom variant="subtitle1" {...props} />
    },
    h4: {
      component: (props: any) => <Typography gutterBottom variant="caption" paragraph {...props} />
    },
    p: {
      component: (props: any) => <Typography paragraph {...props} />
    },
    // li: {
    //   component: (props: any) => (
    //     <li>
    //       <Typography component="span" {...props} />
    //     </li>
    //   )
    // },
    ul: {
      component: (props: any) => (
        <Typography paragraph {...props}>
          <ul {...props} style={{ paddingLeft: 32 }}></ul>
        </Typography>
      )
    },
    table: MarkdownTable,
    thead: MarkdownTableHead,
    tbody: MarkdownTableBody,
    tr: MarkdownTableRow,
    th: MarkdownTableCell,
    td: MarkdownTableCell
  }
};

const Markdown = (props: any) => {
  return <ReactMarkdown options={options} {...props}></ReactMarkdown>;
};

export default Markdown;
