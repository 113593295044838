import { useEffect, useState } from 'react';
// material
import { Stack } from '@mui/material';

import TypeformPage from '../../../pages/TypeformPage';
//
import { MotionInView } from '../../animate';
import LoadingScreen from '../../LoadingScreen';

// ----------------------------------------------------------------------

export default function ContactForm() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadingTimeout = setTimeout(() => setIsLoading(false), 1500);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <Stack>
      <MotionInView sx={{ height: '700px' }}>
        {isLoading && <LoadingScreen />}
        <TypeformPage formId={'id9m9bBc'} />
      </MotionInView>
    </Stack>
  );
}
