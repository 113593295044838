// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Container, Typography, Button, Link, Stack } from '@mui/material';
//
import { varFadeInUp, MotionInView } from '../../animate';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { REGISTER_PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(24, 0),
  backgroundImage:
    theme.palette.mode === 'light'
      ? `linear-gradient(180deg, ${theme.palette.grey[300]} 0%, ${alpha(
          theme.palette.grey[300],
          0
        )} 100%)`
      : 'none'
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const problemsAndSolutionsList = [
  {
    id: 'wrongFile',
    title: 'You receive images with the wrong dimensions, resolution or file type',
    description:
      'Repo File Sync Automatically checks the image properties when they are uploaded based on your custom requirements, this saves you time neededing to check the images and get back to the uploader with changes you need.'
  },
  {
    id: 'memberAway',
    title: 'Images were sent to another team members email but they are away',
    description:
      'Repo File Sync stores all images in a shared space that can be accessed by anyone in your team.'
  },
  {
    id: 'lostFiles',
    title: 'I cant find the images that were sent to me',
    description:
      'Often files can be hard to track down in different file sharing applications, such as email, dropbox, google drive etc. Repo File Sync being your single entry point for receiving files ensures that files come to one location.'
  },
  {
    id: 'noPermissions',
    title: 'I dont have permission to view the files i have been sent',
    description:
      'You control the place files are sent to, you will always have permission to view and use the files sent to you with Repo File Sync.'
  },
  {
    id: 'tooManyEmails',
    title: 'Clogged up email with too many emails about images and attachments',
    description:
      'Repo File Sync removes the need to receive images and links via email, all files sent to you will be stored, kept safe in a single location to keep your inbox clean.'
  },
  {
    id: 'deadlinesMissed',
    title: 'Deadlines being missed because clients forget to send images',
    description:
      'Repo File Sync can send automatic reminders when the files havent been uploaded and the due date is approaching, this helps make sure that you have the files you need at the right time.'
  }
];
// ----------------------------------------------------------------------

export default function LandingProblemsSolved() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={10}>
            <Stack spacing={3}>
              <MotionInView variants={varFadeInUp}>
                <Typography variant="h2">Do you have any of these problems?</Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Typography
                  sx={{
                    color: isLight ? 'text.secondary' : 'common.white'
                  }}
                >
                  These are some of the common problems our users no longer deal with, see how Repo
                  File Sync solves these common issues developers face.
                </Typography>
              </MotionInView>

              <Box>
                {problemsAndSolutionsList.map(({ id, title, description }) => (
                  <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
                    <AccordionSummary aria-controls={id + '-content'} id={id + 'panel1d-header'}>
                      <Typography>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>

              <Typography
                sx={{
                  mb: 5,
                  color: isLight ? 'text.secondary' : 'common.white',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                Yes i have those problems!
              </Typography>
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Button
                  size="large"
                  variant="contained"
                  component={Link}
                  target="_blank"
                  href={REGISTER_PATH_DASHBOARD}
                >
                  I want to try Repo File Sync!
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
