import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack } from '@mui/material';

import { PLAN_TYPE } from './PLAN_TYPE';
import { REGISTER_PATH_DASHBOARD } from '../../../routes/paths';
import Label from '../../Label';
// import Label from '../../Label';

// routes

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  height: '100%',
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

type PricingPlanCardProps = {
  card: {
    subscription: string;
    price: number;
    caption: string;
    icon?: JSX.Element;
    lists: {
      text: string;
      isAvailable: boolean;
    }[];
    planType: PLAN_TYPE;
  };
  index: number;
};

export default function PricingPlanCard({ card, index }: PricingPlanCardProps) {
  const { subscription, icon, price, caption, lists } = card;

  // TODO Get plan type from auth, this is just hard coded.
  const isAuthenticated = false;
  const isCurrentUsersPlan = card.planType === PLAN_TYPE.STARTER;

  return (
    <RootStyle>
      {index === 1 && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute'
          }}
        >
          POPULAR
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          $
        </Typography>
        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? 'Free' : price}
        </Typography>
      </Box>

      <Typography
        gutterBottom
        component="span"
        variant="subtitle2"
        sx={{
          color: 'text.secondary'
        }}
      >
        /month
      </Typography>
      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize'
        }}
      >
        {caption}
      </Typography>

      {icon && <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>}

      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1, flexGrow: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>

      <Button
        href={REGISTER_PATH_DASHBOARD + '/' + card.planType}
        fullWidth
        size="large"
        variant="contained"
        disabled={isAuthenticated ? isCurrentUsersPlan : false}
      >
        {!isAuthenticated && 'Create Account'}
        {isAuthenticated && <>{isCurrentUsersPlan ? 'Current Plan' : 'Upgrade'}</>}
      </Button>
    </RootStyle>
  );
}
