// material
import { Grid, Container } from '@mui/material';
// components
import PricingPlanCard from './PricingPlanCard';
import { PLAN_TYPE } from './PLAN_TYPE';
//

// ----------------------------------------------------------------------

const PLANS = [
  // {
  //   subscription: 'Starter',
  //   // icon: <PlanFreeIcon />,
  //   price: 0,
  //   caption: 'For creatives',
  //   lists: [
  //     { text: 'Receive images up to 15mb', isAvailable: true },
  //     { text: 'Uploads expire after 14 days', isAvailable: true },
  //     { text: 'Storage: (temporary) Up to 1GB', isAvailable: true },
  //     { text: 'Multi User', isAvailable: false }
  //   ],
  //   planType: PLAN_TYPE.STARTER,
  //   planLookupKey: ''
  // },
  {
    subscription: 'Professional',
    price: 4,
    caption: 'For individual developers',
    lists: [
      { text: 'Up to 10 destination repositories', isAvailable: true },
      { text: 'Up to 25 sync files', isAvailable: true },
      { text: 'Automatic creation of Sync Pull Requests', isAvailable: true },
      { text: 'Multi User', isAvailable: false }
    ],
    planType: PLAN_TYPE.PROFESSIONAL,
    planLookupKey: 'price_1Keel8HgIayQ1mJno0BITP8B'
  },
  {
    subscription: 'Team',
    price: 35,
    caption: 'For multiple developers',
    lists: [
      { text: 'Up to 50 destination repositories', isAvailable: true },
      { text: 'Unlimited sync files', isAvailable: true },
      { text: 'Automatic creation of Sync Pull Requests', isAvailable: true },
      { text: 'Multi User', isAvailable: true },
      { text: 'Single Sign On Integraton', isAvailable: false },
      { text: 'Customer Support', isAvailable: false }
    ],
    planType: PLAN_TYPE.TEAM,
    planLookupKey: 'price_1Keel2HgIayQ1mJnkvZOVh3Y'
  },
  {
    subscription: 'Enterprise',
    price: 1499,
    caption: 'For multiple development teams',
    lists: [
      { text: 'Unlimited destination repositories', isAvailable: true },
      { text: 'Unlimited sync files', isAvailable: true },
      { text: 'Automatic creation of Sync Pull Requests', isAvailable: true },
      { text: 'Multi User', isAvailable: true },
      { text: 'Single Sign On Integraton', isAvailable: true },
      { text: 'Customer Support', isAvailable: true }
    ],
    planType: PLAN_TYPE.ENTERPRISE,
    planLookupKey: 'price_1Keel2HgIayQ1mJnkvZOVh3Y'
  }
];

// ----------------------------------------------------------------------

// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
const PricingTable = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {PLANS.map((card, index) => (
          <Grid item xs={12} md={4} key={card.subscription}>
            <PricingPlanCard card={card} index={index} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingTable;
