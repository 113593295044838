import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Alert, Button } from '@mui/material';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_WAITLIST } from '../../routes/paths';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  plan: string;
  afterSubmit?: string;
};

export default function RegisterForm() {
  const isMountedRef = useIsMountedRef();
  let navigate = useNavigate();
  let { clickedPlan } = useParams();

  const handleSave = async (values: InitialValues) => {
    return fetch(
      'https://lcbgj6s5ej.execute-api.us-east-1.amazonaws.com/prod/repofilesync-signup-email-capture',
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    );
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      email: '',
      plan: clickedPlan || 'no_plan'
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await handleSave(values);
        navigate(PATH_WAITLIST);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Button fullWidth size="large" type="submit" variant="contained" disabled={isSubmitting}>
            Sign Up
          </Button>
          <Button
            fullWidth
            size="large"
            variant="text"
            onClick={async () => {
              await handleSave(values);
              navigate(PATH_DASHBOARD);
            }}
          >
            Back
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
