// material
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
// utils
//
import { varFadeInUp, varFadeInRight, MotionInView } from '../../animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function AboutWhat() {
  const textTyporgrapyhStyles = {
    color: (theme: any) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white')
  };
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFadeInUp}>
                  <Typography variant="h2">Who is</Typography>
                  <Typography variant="h2" sx={{ color: 'primary.main' }}>
                    Repo File Sync?
                  </Typography>
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <MotionInView variants={varFadeInRight}>
              <Stack spacing={2}>
                <Typography sx={textTyporgrapyhStyles}>
                  We are a team of software engineers with a passion for improving development
                  processes. We want to build tools and solutions for software development teams
                  that care about quality and efficiency
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  Repo File Sync came out of the fustration of always having a different editor
                  config in different projects
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  We have built Repo File Sync to automate the manual task of ensuring that config
                  dot files are in sync across multiple projects
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  Our service has been built to suit the needs of development shops with more than
                  100 repository's
                </Typography>
              </Stack>
            </MotionInView>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
