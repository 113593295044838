import { Widget } from '@typeform/embed-react';

type TypeformPageProps = {
  formId: string;
};

const TypeformPage = ({ formId }: TypeformPageProps) => {
  return <Widget id={formId} style={{ width: '100%', height: '100%' }} className="my-form" />;
};

export default TypeformPage;
