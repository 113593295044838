// material
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// utils
//
import { MHidden } from '../../@material-extend';
import { varFadeInUp, varFadeInRight, MotionInView } from '../../animate';

import Markdown from '../../markdown';
/* eslint import/no-webpack-loader-syntax: off */
import UserGuidelines from '!!raw-loader!../legal/docs/User-Guidelines.md';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function UserGuidelinesContent() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <MHidden width="mdDown">
            <Grid item xs={12} md={3} lg={4}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12}>
                  <MotionInView variants={varFadeInUp}>
                    <Typography variant="h2" sx={{ color: 'primary.main' }}>
                      User Guidelines
                    </Typography>
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </MHidden>

          <Grid item xs={12}>
            <MotionInView variants={varFadeInRight}>
              <Markdown>{UserGuidelines}</Markdown>
            </MotionInView>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
