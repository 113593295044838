// material
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { PricingTable } from '../components/_external-pages/pricing';
//

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundColor: '#f4f6f8',
  paddingTop: theme.spacing(15),
  minHeight: '100%',
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------
// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
export default function Pricing() {
  return (
    <RootStyle title="Pricing | Repo File Sync">
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" paragraph>
          Flexible plans for your needs
        </Typography>
        <Typography align="center" paragraph sx={{ color: 'text.secondary' }}>
          Choose your plan and start sharing files
        </Typography>
        <PricingTable />
      </Container>
    </RootStyle>
  );
}
