// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
//
import { MotionInView, varFadeInUp } from '../../animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }: any) => ({
  padding: theme.spacing(15, 0),
  backgroundImage:
    theme.palette.mode === 'light'
      ? `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)}  0%,${
          theme.palette.grey[300]
        }  100%)`
      : 'none'
}));

// ----------------------------------------------------------------------

export default function LandingThemeColor() {
  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ position: 'relative', textAlign: 'center' }}>
        <MotionInView variants={varFadeInUp}>
          <Typography
            component="p"
            variant="overline"
            sx={{ mb: 2, color: 'text.disabled', display: 'block' }}
          >
            Manage files across a few repos or across an entire organisation
          </Typography>
        </MotionInView>

        <MotionInView variants={varFadeInUp}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            A better development experience
          </Typography>
        </MotionInView>

        <MotionInView variants={varFadeInUp}>
          <Typography
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'text.primary')
            }}
          >
            Repo File Sync will automatically check files and create pull requests to fix any
            inconsistencys that may arise
          </Typography>
        </MotionInView>

        <Box sx={{ position: 'relative' }}>
          <Box component="img" src="/static/home/theme-color/grid.png" />

          <Box sx={{ position: 'absolute', top: 0 }}>
            <img alt="screen" src={`/static/home/edit-requirements.png`} />
          </Box>
        </Box>
      </Container>
    </RootStyle>
  );
}
