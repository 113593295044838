// material
import { styled } from '@mui/material/styles';
import { Stack, Box, Grid, Container, Typography } from '@mui/material';
// utils
//
import { MotionInView, varFadeInDown, varFadeInUp } from '../../animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function LandingWhat() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ textAlign: 'center', mb: 3 }}>
              Let Repo File Sync keep all your configuration files in sync
            </Typography>
          </MotionInView>
        </Box>
        <Grid item xs={12}>
          <MotionInView variants={varFadeInUp}>
            <Stack spacing={2}>
              <Box>
                <Typography fontWeight={'bold'} sx={{ color: 'primary.main' }} display="inline">
                  Repo File Sync{' '}
                </Typography>
                <Typography
                  display="inline"
                  fontWeight={'bold'}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                  }}
                >
                  is a specialized file sharing platform that provides a new and improved way for
                  software developers to copy and sync files across multiple projects
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                }}
              >
                By automatically keeping files in sync you can avoid having repository's getting out
                of sync or relying on packages
              </Typography>
            </Stack>
          </MotionInView>
        </Grid>
      </Container>
    </RootStyle>
  );
}
